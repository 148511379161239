import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { TRANSLATIONS_FR } from './fr';
import { TRANSLATIONS_EN } from './en';
import { TRANSLATIONS_DE } from './de';
import { TRANSLATIONS_ES } from './es';
import { TRANSLATIONS_IT } from './it';
import { TRANSLATIONS_NL } from './nl';
import { TRANSLATIONS_PT } from './pt';

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      FR: {
        translation: TRANSLATIONS_FR,
      },
      EN: {
        translation: TRANSLATIONS_EN,
      },
      DE: {
        translation: TRANSLATIONS_DE,
      },
      ES: {
        translation: TRANSLATIONS_ES,
      },
      IT: {
        translation: TRANSLATIONS_IT,
      },
      NL: {
        translation: TRANSLATIONS_NL,
      },
      PT: {
        translation: TRANSLATIONS_PT,
      },
    },
    fallbackLng: 'EN',
  });
